import React from 'react';

import {
    sectionContainer,
    wrapper,
    offsetContainer,
    navigation,
    pagination,
    overlay,
    overlayWrapper,
    navButton,
} from './banner-slider.module.scss';

import { ISection } from '../../models/section.model';
import IBanner from '../../models/banner.model';
import { useSwiperLoading } from '../../hooks/use-swiper-loading';

import Slider from '../hoc/slider';
import Banner from '../molecules/banner';
import Section from '../hoc/section';

export interface IBannerSlider extends ISection {
    items: {
        banners: IBanner[];
    };
}

interface IBannerSliderProps {
    className?: string;
    section: IBannerSlider;
    TitleTag?: React.ElementType;
}

const BannerSlider: React.FC<IBannerSliderProps> = ({ className = '', section, TitleTag }) => {
    const {
        sectionId,
        items: { banners },
        css,
        style,
        sequence,
    } = section;
    const { loading, paginationRef, navigationNextRef, navigationPrevRef } = useSwiperLoading();

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionContainer}`}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <Slider
                    loading={loading}
                    spaceBetween={0}
                    rewind={true}
                    pagination={{
                        el: paginationRef.current,
                        clickable: true,
                    }}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                >
                    {banners.map((banner, index) => {
                        return (
                            <Banner
                                key={`banner-${banner.bannerId}`}
                                banner={banner}
                                TitleTag={TitleTag}
                                offsetClassName={offsetContainer}
                                loading={sequence < 3 && index === 0 ? 'eager' : 'lazy'}
                            />
                        );
                    })}
                </Slider>
                <div className={overlay}>
                    <div className={overlayWrapper}>
                        <div className={navigation}>
                            <button
                                className={navButton}
                                ref={navigationPrevRef}
                                style={{ padding: '10px' }}
                            >
                                {'‹'}
                            </button>
                            <button
                                className={navButton}
                                ref={navigationNextRef}
                                style={{ padding: '10px' }}
                            >
                                {'›'}
                            </button>
                        </div>
                        <div ref={paginationRef} className={pagination} />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default BannerSlider;

/*TODO:
    - Skalowanie
    - Dodac poprwane przyciski
    - Animacja przy wejsciu na zescrollowanej stronie
    - Sprawdzic czego brakuje
*/
